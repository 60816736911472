import localforage from 'localforage'

class LocalStorageHandler {
  constructor({ storeName = 'local-data' } = {}) {
    this.store = localforage.createInstance({
      storeName
    })
  }

  async set(key, val) {
    try {
      await this.store.setItem(key, val)
    } catch (e) {
      //
    }
  }

  async get(key) {
    try {
      return await this.store.getItem(key)
    } catch (e) {
      //
    }
  }
}

export const localStore = new LocalStorageHandler()
